<template>
  <div class="camera-controls">
    <router-link class="camera-controls__info-link" to="/about" aria-label="Go to About page">
      <span>?</span>
      <span class="camera-controls__label" v-if="legend">Hilfe</span>
    </router-link>
    <button v-if="centerButton==='record'" :ontouchstart="touchstart" :ontouchend="touchend" class="camera-controls__button" type="button" aria-label="Record">
      <svg class="camera-controls__play-icon" width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="outline" d="M72 36C72 55.8823 55.8823 72 36 72C16.1177 72 0 55.8823 0 36C0 16.1177 16.1177 0 36 0C55.8823 0 72 16.1177 72 36ZM6.11711 36C6.11711 52.5039 19.4961 65.8829 36 65.8829C52.5039 65.8829 65.8829 52.5039 65.8829 36C65.8829 19.4961 52.5039 6.11711 36 6.11711C19.4961 6.11711 6.11711 19.4961 6.11711 36Z" fill="currentColor"/>
        <path class="circle" d="M64 36C64 50.9117 50.9117 64 36 64C21.0883 64 8 50.9117 8 36C8 21.0883 21.0883 8 36 8C50.9117 8 64 21.0883 64 36Z" fill="currentColor"/>
        <circle class="ring" cx="36" cy="36" r="33" stroke="white" stroke-width="6"/>
      </svg>
      <span class="camera-controls__label" v-if="legend">Mache ein Foto oder Video</span>
    </button>
    <button v-if="centerButton==='qr'" :onclick="qrclick" class="camera-controls__button camera-controls__button_qr" type="button" aria-label="Camera">
      <qrIcon />
    </button>
    <button v-if="centerButton==='map'" :onclick="mapclick" class="camera-controls__button camera-controls__button_map" type="button" aria-label="Map">
      <img src="../assets/images/map.png" alt="">
    </button>
    <router-link v-if="!prizeNotify" class="camera-controls__info-link" to="/prize" onclick="store.set('prizeStep','validated');" aria-label="Go to Prizes page">
      <GiftsIcon />
      <span class="camera-controls__label" v-if="legend">Gewinne</span>
      <span class="camera-controls__notify" v-if="prizeNotify"></span>
    </router-link>
    <router-link v-if="prizeNotify" class="camera-controls__info-link" to="/prize" onclick="store.set('prizeStep','prize');" aria-label="Go to Prize page">
      <GiftsIcon />
      <span class="camera-controls__label" v-if="legend">Gewinne</span>
      <span class="camera-controls__notify" v-if="prizeNotify"></span>
    </router-link>
  </div>
</template>

<script>
import GiftsIcon from '@/assets/images/gift.svg?inline'
import qrIcon from '@/assets/images/qr-code.svg?inline'
export default {
  name: 'CameraControls',
  data () {
    return {
    }
  },
  props: {
    qrclick: {
      required: false,
      type: Function
    },
    mapclick: {
      required: false,
      type: Function
    },
    touchstart: {
      required: false,
      type: Function
    },
    touchend: {
      required: false,
      type: Function
    },
    centerButton: {
      required: false,
      default: 'record',
      type: String
    },
    legend: {
      required: false,
      type: Boolean
    },
    prizeNotify: {
      required: false,
      type: Boolean
    },
    cameraShown: {
      required: false,
      type: Boolean,
      default: false
    },
    mapShown: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  components: {
    GiftsIcon,
    qrIcon
  },
  mounted () {
    const legends = [].slice.call(document.querySelectorAll('.camera-controls__label'))
    setTimeout(function () {
      legends.forEach(el => el.classList.add('hidden'))
    }, 5000)
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.camera-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 145px 17px 15px;
  background-image: linear-gradient(to top, #000000 30.35%, rgba(0, 0, 0, 0) 100%);
  z-index: 1;
}

.camera-controls__info-link {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  text-decoration: none;
  text-align: center;
  font-size: 21px;
  line-height: 1.4;
  color: #222;
  background-color: $foreground-color;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:first-child {
    .camera-controls__label {
      left: 0;
      transform: translateX(0);
      margin-bottom: 38px;
      text-align: left;

      &::before,
      &::after {
        left: 18px;
        transform: translateX(-50%);
      }
    }
  }
  &:last-child {
    .camera-controls__label {
      left: auto;
      right: 0;
      transform: translateX(0);
      margin-bottom: 38px;
      text-align: right;

      &::before,
      &::after {
        left: auto;
        right: 18px;
        transform: translateX(50%);
      }
    }
  }
}

.camera-controls__label {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 47px;
  width: 120px;
  text-align: center;
  font-size: 14px;
  line-height: 1.3;
  color: $foreground-color;
  transition: opacity 0.3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    width: 1px;
    height: 25px;
    background-color: $foreground-color;
    border-radius: 1px;
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
    width: 5px;
    height: 5px;
    background-color: $foreground-color;
    border-radius: 50%;
  }
}


.camera-controls__button {
  position: relative;
  display: block;
  padding: 0;
  width: 72px;
  height: 72px;
  color: $foreground-color;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  transition: all 0.1s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  .camera-controls__play-icon {
    transform: scale(-1) rotate(90deg);
  }

  .circle {
    transform-origin: center;
    transition: all 0.3s ease;
  }

  .outline {
    transform-origin: center;
    transition: all 0.3s ease;
  }

  .ring {
    transform-origin: center;
    transition: all 0.3s ease;
    stroke-dasharray: 226px;
    stroke-dashoffset: 226px;
  }
}


.camera-controls__button.down {
  .circle {
    transform: scale(0.8);
    color: rgba($foreground-color, 0.5);
  }

  .outline {
    color: rgba($foreground-color, 0.5);
  }

  .ring {
    color: rgba($foreground-color, 0.5);
  }
}

.camera-controls__button.video {
  .ring {
    animation: dash 15s linear;
    animation-fill-mode: backwards;
  }
}

.camera-controls__button.saving-video {
  .circle {
    transform: scale(0.8);
    color: rgba($foreground-color, 0.5);
  }

  .outline {
    color: rgba($foreground-color, 0.5);
  }

  .ring {
    color: rgba($foreground-color, 0.5);
    stroke-dasharray: calc(72px * 3.14 / 4);
    animation: swirl 2s linear;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes swirl {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}


.camera-controls__notify {
  position: absolute;
  top: -3px;
  right: -3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-bottom: 1px;
  width: 15px;
  height: 15px;
  font-size: 8px;
  line-height: 10px;
  color: $white;
  background-color: #DA2243;
  border-radius: 50%;
}

.camera-controls__button_qr,
.camera-controls__button_map {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("data:image/svg+xml,%3Csvg width='72' height='72' viewBox='0 0 72 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='36' cy='36' r='27' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  svg,
  img {
    display: block;
    width: 28px;
    height: 28px;
    object-fit: contain;
    object-position: center;
  }
}
</style>
